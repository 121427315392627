<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();
useSeasonSocket();

const handleClickOnGift = () =>
	loginGuard({
		success: () => {
			open("LazyOModalSeasonTower");

			dispatchGAEvent({
				event: "click_button",
				button_name: "season_popup",
				location: "gift_right"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "gift_right" });
		}
	});
</script>
<template>
	<div class="season-gift" @click="handleClickOnGift">
		<NuxtImg
			class="logo"
			src="/nuxt-img/seasons/hunt/sidebar-hunt.png"
			format="webp"
			width="96"
			height="96"
			alt="hunt seasons"
			loading="lazy"
		/>
		<div class="d-flex flex-column">
			<AText :modifiers="['bold', 'uppercase']">{{ t("Try") }}</AText>
			<AText type="x-small">{{ t("Now") }}</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.season-gift {
	width: 90px;
	height: 60px;
	color: var(--neutral-50);
	background: var(--gradient-4);
	filter: drop-shadow(0px 4.5px 4.5px rgba(0, 0, 0, 0.15));
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	align-items: center;
	gap: 4px;
	position: fixed;
	right: 0;
	bottom: 130px;
	z-index: 6;
	cursor: pointer;

	@include media-breakpoint-up(lg) {
		bottom: 300px;
	}

	.logo {
		width: 40px;
		height: 40px;
		margin-left: 8px;
	}
}
</style>
